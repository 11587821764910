$break-tablet: 768px;
$break-mobile: 480px;
.session-notes-card {
  background-color: #FFFFFF;
  box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;

  @media screen and (max-width: $break-mobile){
    margin-right: 0;
  }

  .rating {
    text-align: right;
    margin-right: 14px;
  }

  .cigar-image {
    position: relative;
    img {
      width: 100%;
      max-width: 75px;
      margin: 16px auto;
      border-radius: 50%;
      object-fit: cover;
    }
    .tg-item-overlay-two{
      background-color: rgba(255,255,255,0.85);
      opacity: 0;
      -webkit-transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,-webkit-transform 0.3s ease-in-out;
      -moz-transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,-moz-transform 0.3s ease-in-out;
      -ms-transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,-ms-transform 0.3s ease-in-out;
      -o-transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,-o-transform 0.3s ease-in-out;
      transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,transform 0.3s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: transparent solid 1px;
      box-sizing: border-box;
    }
    .tg-buttons-holder{
      position: absolute;
      display: block;
      height: auto;
      right: 0;
      bottom: 0;
      .tg-link-button{
        opacity: 0;
        float: left;
        transition-delay: .1s;
        background: rgba(255,255,255,0.5);

        -webkit-transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,-webkit-transform 0.3s ease-in-out;
        -moz-transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,-moz-transform 0.3s ease-in-out;
        -ms-transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,-ms-transform 0.3s ease-in-out;
        -o-transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,-o-transform 0.3s ease-in-out;
        transition: background 0.15s ease-in-out,opacity 0.3s ease-in-out,transform 0.3s ease-in-out;
      }
    }
    &:hover .tg-item-overlay-two, &:hover .tg-link-button{
      opacity: 1;
      -webkit-transform: translate3d(0,0,0);
      -moz-transform: translate3d(0,0,0);
      -ms-transform: translate3d(0,0,0);
      -o-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }

    // &:hover .tg-item-overlay-link{
    //   -webkit-transition-delay: .15s;
    //   transition-delay: .15s;
    //   opacity:1
    // }
    .tg-item-overlay-link{
      opacity:0;
      background:rgba(47,191,193,0.9);
      -webkit-transition: opacity 0.3s ease-in-out,-webkit-transform 0.3s ease-in-out;
      -moz-transition: opacity 0.3s ease-in-out,-moz-transform 0.3s ease-in-out;
      -ms-transition: opacity 0.3s ease-in-out,-ms-transform 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out,-o-transform 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out,transform 0.3s ease-in-out;

      position: absolute;
      display: inline-block;
      height: 54px;
      width: 54px;
      margin: 10px;
      left: 38%;
      top: 28%;
      cursor: pointer;
      opacity: 0;
      font-size: 20px;
      border-radius: 50%;

      a.tg-link-button{
        position: absolute;
        z-index:9999;
        display: block;
        height: 54px;
        line-height: 54px;
        text-align: center;
        width: 54px;
        i{
          color: #ffffff;
          fill: #ffffff;
          stroke: #ffffff;
          border-color: #ffffff;
          &:before{
            content: "\e620";
          }

        }
      }
    }
  }


  .name {
    padding: 10px 22px 12px;

    h4 {
      font-size: 1.2em;
      margin: 10px 0px 5px;
      font-style: oblique;
    }

    span {
      font-size: 0.8em;
      line-height: 21px;
      margin-bottom: 17px;
    }
  }
  .last {
    width: 100%;
    float: left;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 22px;
    border-top: 1px solid #f4f4f4;


  }

  .ribbon-container {
    position: absolute;
    top: 15px;
    left: -20px;
    overflow: visible; /* so we can see the pseudo-elements we're going to add to the anchor */
    font-size: 18px; /* font-size and line-height must be equal so we can account for the height of the banner */
    line-height: 18px;

    &:before {
      content:"";
      height: 0;
      width: 0;
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      border-top: 29px solid rgba(0,0,0,.3); /* These 4 border properties create the first part of our drop-shadow */
      border-bottom: 29px solid rgba(0,0,0,.3);
      border-right: 29px solid rgba(0,0,0,.3);
      border-left: 29px solid transparent;
    }

    &:after { /* This adds the second part of our dropshadow */
      content:"";
      height: 3px;
      background: rgba(0,0,0,.3);
      display: block;
      position: absolute;
      bottom: -3px;
      left: 58px;
      right:3px;
    }

    span {
      display:block;
      padding:20px;
      position:relative; /* allows us to position our pseudo-elements properly */
      background:#0089d0;
      overflow:visible;
      height:58px;
      margin-left:29px;
      color:#fff;
      text-decoration:none;

      &:before { /* this creates the "forked" part of our ribbon */
        content:"";
        height: 0;
        width: 0;
        display:block;
        position:absolute;
        top:0;
        right:-29px;
        border-top: 29px solid #0089d0;
        border-bottom: 29px solid #0089d0;
        border-right: 29px solid transparent;
        border-left: 29px solid transparent;
      }

      &:after { /* this creates the "folded" part of our ribbon */
        content:"";
        height: 0;
        width: 0;
        display:block;
        position:absolute;
        bottom:-15px;
        left:0;
        border-top: 15px solid #004a70;
        border-right: 15px solid transparent;
      }

      &:hover {
        background:#009ff1;

        &:before { /* this makes sure that the "forked" part of the ribbon changes color with the anchor on :hover */
          border-top: 29px solid #009ff1;
          border-bottom: 29px solid #009ff1;
        }
      }
    }
  }

}


// b51a6af6503bd43e9ef944a1de99f788
